/* index.css */
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

body {
  margin: 0;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(/src/assets/NotoSansKR-Regular.woff) format("woff2"),
    url(/src/assets/NotoSansKR-Regular.woff) format("woff");
}
